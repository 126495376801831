import WYSIWYG from '@components/ui/WYSIWYG'
import config from '@config/seo_meta.json'
import Image from 'next/legacy/image'

interface Props {
  slice: any
}

const HomepageKsps = ({ slice }: Props) => {
  const kspsText = slice?.primary?.KSPsText
  return (
    <section className="w-full bg-brand-grey">
      <div className="wrapper pt-30 pb-16">
        {kspsText && (
          <div className="heading-mobile-3 lg:heading-3 m-auto max-w-5xl text-left font-roboto font-light text-brand-dark-grey lg:text-center">
            <WYSIWYG content={kspsText} />
          </div>
        )}
        <div className="mt-12 flex flex-wrap justify-between gap-x-6 gap-y-10 lg:mt-20 lg:mb-4">
          {slice.items?.map((item: any, index: number) => {
            const ImageUrl = item?.KSPsImage?.url
            const ImageWidth = item?.KSPsImage?.dimensions?.width
            const ImageHeight = item?.KSPsImage?.dimensions?.height
            const title = item?.title

            return (
              <div className="w-20 lg:mx-4 lg:w-auto" key={index}>
                <div className="flex justify-center">
                  {ImageUrl && (
                    <Image
                      src={ImageUrl}
                      alt={title || config.openGraph.images[0].alt}
                      width={ImageWidth}
                      height={ImageHeight}
                    />
                  )}
                </div>
                {ImageUrl && (
                  <div className="mt-2 flex text-center text-body2 text-brand-dark-grey">
                    {title}
                  </div>
                )}
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}

export default HomepageKsps
